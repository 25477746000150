<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="设备名称" prop="deviceName">
        <a-input v-model="deviceName.value" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="设备编号" prop="telecontrolID">
        <a-input v-model="telecontrolID.value" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="设备型号" prop="model">
        <a-input v-model="model.value" placeholder="" :disabled="true"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList,
  getDeviceparamChildLisByParentId, modifyParamsBatchLog, setDeviceAudioparamValueList,
} from "A/ai";
import moment from "moment";
export default {
  name: "deviceinfo",
  data() {
    return {
      id:0,
      deviceName:[
        {
          name: "deviceName",
          id: 0,
          ro: false,
          value: ""
        }
      ],
      telecontrolID:[
        {
          name: "telecontrolID",
          id: 1,
          ro: false,
          value: null
        }
      ],
      model:[
        {
          name: "model",
          id: 2,
          ro: true,
          value: ""
        }
      ],
      methodList: [],
      tabKey: '529',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'GET',
                url: '/system/device-info',
              }
            }
          ]
        };
        getDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.deviceName=res.item[0].params.body[0]
            this.telecontrolID=res.item[0].params.body[1]
            this.model=res.item[0].params.body[2]
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig(){
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'PUT',
                url:'/system/device-info',
                body: this.deviceName,
              }
            },
            {
              params: {
                method: 'PUT',
                url:'/system/device-info',
                body: this.telecontrolID,
              }
            }
          ]
        };
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
        this.showLoading();
        setDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' ) {
            this.$message.success('操作成功');
            let param = {
              username:this.username,
              paramsobj:this.devicename,
              paramsdate:paramsdate,
              devicenum:this.devicenum,
              devicegroupid:"",
              orderresult:'成功',
              paramstype:'0',
              parentid:"",
              paramsdata:this.paramsdata,
            }
            modifyParamsBatchLog(param)
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      // }
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>