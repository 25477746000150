<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="下发模式选择" prop="sNightToDay">
        <a-select id="status" placeholder="请选择" v-model="networkConfigs.ipv4.sV4Method">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option value="dhcp">自动</a-select-option>
          <a-select-option value="manual">手动</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="networkConfigs.ipv4.sV4Method=='manual'" label="设备IP" prop="sV4Address">
        <a-input v-model="networkConfigs.ipv4.sV4Address" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="networkConfigs.ipv4.sV4Method=='manual'" label="网关" prop="sV4Gateway">
        <a-input v-model="networkConfigs.ipv4.sV4Gateway" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="networkConfigs.ipv4.sV4Method=='manual'" label="掩码" prop="sV4Netmask">
        <a-input v-model="networkConfigs.ipv4.sV4Netmask" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="DNS1" prop="sDNS1">
        <a-input v-model="networkConfigs.link.sDNS1" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="DNS2" prop="sDNS2">
        <a-input v-model="networkConfigs.link.sDNS2" placeholder=""></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId, modifyParamsBatchLog, setDeviceAudioparamValueList,
} from "A/ai";
import {getCache} from "U/index";
import moment from "moment";
export default {
  name: "network",
  data() {
    return {
      networkConfigs:{
        ipv4: {
          sV4Address: '',
          sV4Gateway: null,
          sV4Method: null,
          sV4Netmask: null
        },
        link:{
          iDuplex:null,
          iNicSpeed:null,
          iPower:null,
          sAddress:'',
          sDNS1:'',
          sDNS2:'',
          sInterface:'',
          sNicSpeed:'',
          sNicSpeedSupport:'',
        }
      },
      devicenum:'',
      devicename:'',
      methodList: [],
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'GET',
                url: '/network/lan',
              }
            }
          ]
        };
        getDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.networkConfigs=res.item[0].params.body
            this.networkConfigs.ipv4=res.item[0].params.body.ipv4
            this.networkConfigs.link=res.item[0].params.body.link
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig(){
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'PUT',
                url:'/network/lan',
                body: this.networkConfigs.ipv4.sV4Method=='dhcp'?{
                  ipv4: {
                    sV4Method: "dhcp"
                  },
                  link:{
                    sNicSpeed:'Auto',
                    sDNS1:this.networkConfigs.link.sDNS1,
                    sDNS2:this.networkConfigs.link.sDNS2,
                  }
                }:{
                  ipv4: {
                    sV4Address: this.networkConfigs.ipv4.sV4Address,
                    sV4Gateway: this.networkConfigs.ipv4.sV4Gateway,
                    sV4Method: "manual",
                    sV4Netmask: this.networkConfigs.ipv4.sV4Netmask
                  },
                  link:{
                    sNicSpeed:'Auto',
                    sDNS1:this.networkConfigs.link.sDNS1,
                    sDNS2:this.networkConfigs.link.sDNS2,
                  }
                },
              }
            }
          ]
        };
        this.showLoading();
        setDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' ) {
            this.$message.success('操作成功');
            let param = {
              username:this.username,
              paramsobj:this.devicename,
              paramsdate:paramsdate,
              devicenum:this.devicenum,
              devicegroupid:"",
              orderresult:'成功',
              paramstype:'0',
              parentid:"",
              paramsdata:this.paramsdata,
            }
            modifyParamsBatchLog(param)
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      // }
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>