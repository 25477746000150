<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="录像文件上传使能" prop="UPLOAD_VIDEO_ENABLE">
        <a-select v-model="aiConfigs.UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList,
  getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList,
  modifyParamsBatchLog,
  setDeviceAudioparamValueList,
  setDeviceparamValueList,
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "videoParams",
  props:{
    VideoParamsFlag:{
      default: null
    }
  },
  data() {
    return {
      aiConfigs: {
        UPLOAD_VIDEO_ENABLE:null,
      },
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              method: 'GET',
              url: '/ai',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.aiConfigs = res.item[0].params.body
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig() {
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              methord: 'PUT',
              url: '/ai',
              body: this.aiConfigs,
            }
          }
        ]
      };
      this.paramsdata = "录像文件上传使能" + "," + this.aiConfigs.UPLOAD_VIDEO_ENABLE + ","
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>