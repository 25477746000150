<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="MqttFlag.mqttFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item v-if="MqttFlag.mqttFlag==0" label="客户端ID" prop="client_id">
        <a-input v-model="mqttConfigs.client_id" placeholder="" :disabled="true"></a-input>
      </a-form-model-item>
    </a-form-model>
    <a-form-model v-if="MqttFlag.mqttFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="客户端ID" prop="client_id">
          <a-input v-model="mqttConfigs.client_id" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item  label="后台IP" prop="ip">
          <a-input v-model="mqttConfigs.ip" placeholder="" ></a-input>
        </a-form-model-item>
        <a-form-model-item  label="后台端口" prop="port">
          <a-input v-model="mqttConfigs.port" placeholder="" ></a-input>
        </a-form-model-item>
        <a-form-model-item  label="用户名" prop="username">
          <a-input v-model="mqttConfigs.username" placeholder="" ></a-input>
        </a-form-model-item>
        <a-form-model-item  label="密码" prop="password">
          <a-input type="password" v-model="mqttConfigs.password" placeholder="" ></a-input>
        </a-form-model-item>
        <a-form-model-item  label="Tls" prop="tls">
          <a-input v-model="mqttConfigs.tls" placeholder="" ></a-input>
        </a-form-model-item>
        <a-form-model-item label="secret" prop="secret">
          <a-input v-model="mqttConfigs.secret" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="key" prop="key">
          <a-input v-model="mqttConfigs.key" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="心跳间隔" prop="PARA_MQTT_HEARTBEAT_INTERVAL">
          <a-input v-model="mqttConfigs.PARA_MQTT_HEARTBEAT_INTERVAL" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="主题" prop="PARA_MQTT_USER_SERVER_TOPIC">
          <a-input v-model="mqttConfigs.PARA_MQTT_USER_SERVER_TOPIC" placeholder=""></a-input>
        </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "mqtt",
  props:{
    MqttFlag:{
      default: null
    }
  },
  data() {
    return {
      mqttConfigs:{
        client_id:'',
        ip:'',
        port:'',
        username:'',
        password:'',
        tls:'',
        secret:'',
        key:'',
        PARA_MQTT_HEARTBEAT_INTERVAL:'',
        PARA_MQTT_USER_SERVER_TOPIC:'',
      },
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      devicename:'',
      paramsdata:[],
      username:'',
      methodList: [],
      tabKey: '404',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/mqttclient',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.mqttConfigs=res.item[0].params.body
          this.mqttConfigs.client_id = res.item[0].params.body.client_id
          this.mqttConfigs.ip = res.item[0].params.body.ip
          this.mqttConfigs.port = res.item[0].params.body.port
          this.mqttConfigs.username = res.item[0].params.body.username
          this.mqttConfigs.password = res.item[0].params.body.password
          this.mqttConfigs.tls = res.item[0].params.body.tls
          this.mqttConfigs.secret = res.item[0].params.body.secret
          this.mqttConfigs.key = res.item[0].params.body.key
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig() {
      let paramsBody = {
        client_id: this.mqttConfigs.client_id,
        ip: this.mqttConfigs.ip,
        port: Number(this.mqttConfigs.port),
        username: this.mqttConfigs.username,
        password: this.mqttConfigs.password,
        tls: Number(this.mqttConfigs.tls),
        secret: this.mqttConfigs.secret,
        key: this.mqttConfigs.key,
        PARA_MQTT_HEARTBEAT_INTERVAL:Number(this.mqttConfigs.PARA_MQTT_HEARTBEAT_INTERVAL),
        PARA_MQTT_USER_SERVER_TOPIC:this.mqttConfigs.PARA_MQTT_USER_SERVER_TOPIC,
      };
      this.paramsdata = "客户端ID" + "," + this.mqttConfigs.client_id + ","
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url: '/mqttclient',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else {
        this.getParamsConfig();
        if(this.MqttFlag.mqttFlag==1){
          this.status=true
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>