<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-top: 1.6%;margin-right: 6.1%;margin-left: 1%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="LinkageFlag.linkageFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="困人故障" prop="digital">
        <a-checkbox-group v-model="faultlists" @change="faultChange">
          <a-row>
            <a-col :span="12">
              <a-checkbox value="faultmessage">
                短信联动使能
              </a-checkbox>
            </a-col>
            <a-col :span="12">
              <a-checkbox value="faultphone">
                电话联动使能
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="困人故障联动号码" prop="faultphonelists">
        <a-input v-model="faultphonelists" placeholder="多个电话请使用英文逗号分隔"></a-input>
      </a-form-model-item>
      <a-form-model-item label="一键报警">
        <a-checkbox-group v-model="alarmlists" @change="alarmChange">
          <a-row>
            <a-col :span="12">
              <a-checkbox value="alarmmessage">
                短信联动使能
              </a-checkbox>
            </a-col>
            <a-col :span="12">
              <a-checkbox value="alarmphone">
                电话联动使能
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="一键报警联动号码" prop="alarmphonelists">
        <a-input v-model="alarmphonelists" placeholder="多个电话请使用英文逗号分隔"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电梯有人长时间驻留">
        <a-checkbox-group v-model="lingeringlists" @change="lingeringChange">
          <a-row>
            <a-col :span="12">
              <a-checkbox value="lingeringmessage">
                短信联动使能
              </a-checkbox>
            </a-col>
            <a-col :span="12">
              <a-checkbox value="lingeringphone">
                电话联动使能
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="电梯有人长时间驻留联动号码" prop="lingerphonelists">
        <a-input v-model="lingerphonelists" placeholder="多个电话请使用英文逗号分隔"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电瓶车进入轿厢">
        <a-checkbox-group v-model="vehiclelists" @change="vehicleChange">
          <a-row>
            <a-col :span="12">
              <a-checkbox value="vehiclemessage">
                短信联动使能
              </a-checkbox>
            </a-col>
            <a-col :span="12">
              <a-checkbox value="vehiclephone">
                电话联动使能
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="电瓶车进入轿厢联动号码" prop="vehiclephonelists">
        <a-input v-model="vehiclephonelists" placeholder="多个电话请使用英文逗号分隔"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList,
  modifyDeviceInfo, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import {
  addEventLinkage, getEventLinkageListByCondition,
  getLiftinfoById,
  getLiftinfoListByCondition, getLiftListByCondition,
  getUserdepListByCondition,
  modifyLiftinfo,
  modifyUserdep
} from "A/jcgn";
import {getUserByUserid} from "A/login";
import {getEventListByCondition} from "A/wlw";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "linkage",
  props:{
    LinkageFlag:{
      default: null
    }
  },
  data() {
    return {
      status:false,
      devicemoduletype:'',
      devicenum:'',
      modelnum:'',
      deviceinfoid:'',
      liftid:'',
      faultlists:[],
      alarmlists:[],
      lingeringlists:[],
      vehiclelists:[],

      faultmessage:1,
      faultphone:0,
      faultphonelists:'',
      alarmmessage:1,
      alarmphone:0,
      alarmphonelists:'',
      lingeringmessage:1,
      lingeringphone:0,
      lingerphonelists:'',
      vehiclemessage:1,
      vehiclephone:0,
      vehiclephonelists:'',

    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    faultChange(checkedValues) {
      this.faultmessage=0
      this.faultphone = 0
      checkedValues.filter(item=>{
        if(item=="faultmessage"){
          this.faultmessage=1
        }
        if(item=="faultphone") {
          this.faultphone = 1
        }
      })
    },
    alarmChange(checkedValues) {
      this.alarmmessage=0
      this.alarmphone = 0
      checkedValues.filter(item=>{
        if(item=="alarmmessage"){
          this.alarmmessage=1
        }
        if(item=="alarmphone") {
          this.alarmphone = 1
        }
      })
    },
    lingeringChange(checkedValues) {
      this.lingeringmessage=0
      this.lingeringphone = 0
      checkedValues.filter(item=>{
        if(item=="lingeringmessage"){
          this.lingeringmessage=1
        }
        if(item=="lingeringphone") {
          this.lingeringphone = 1
        }
      })
    },
    vehicleChange(checkedValues) {
      this.vehiclemessage=0
      this.vehiclephone = 0
      checkedValues.filter(item=>{
        if(item=="vehiclemessage"){
          this.vehiclemessage=1
        }
        if(item=="vehiclephone") {
          this.vehiclephone = 1
        }
      })
    },
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      this.deviceinfoid=this.$route.params.deviceinfoid||'';
      this.liftid = this.$route.params.liftid||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getLiftInfo(){
      let params = {
        liftid:this.liftid
      }
      getLiftinfoById(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.userdepid=res.item.maintenancedepLists.userdepid,
          this.userdepname=res.item.maintenancedepLists.userdepname,
          this.levelid=res.item.maintenancedepLists.levelid,
          this.deptype=res.item.maintenancedepLists.deptype
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '88';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getParamsConfig() {
      // this.paramsList = [];
      // let params = {
      //   devicenum: this.devicenum,
      //   data: [
      //     {
      //       modeltype: this.devicemoduletype,
      //       modelnum: this.modelnum,
      //       params: {
      //         methord: 'GET',
      //         url: '/linphone',
      //       }
      //     }
      //   ]
      // };
      // getDeviceparamValueList(params).then(res => {
      //   this.hideLoading();
      //   if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
      //   }
      // }).catch(err => {
      //   this.hideLoading();
      // })
      getEventLinkageListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.faultmessage=res.item[0].faultmessage
          this.faultphone=res.item[0].faultphone
          this.faultphonelists=res.item[0].faultphonelists
          this.alarmmessage=res.item[0].alarmmessage
          this.alarmphone=res.item[0].alarmphone
          this.alarmphonelists=res.item[0].alarmphonelists
          this.lingeringmessage=res.item[0].lingeringmessage
          this.lingeringphone=res.item[0].lingeringphone
          this.lingerphonelists=res.item[0].lingerphonelists
          this.vehiclemessage=res.item[0].vehiclemessage
          this.vehiclephone=res.item[0].vehiclephone
          this.vehiclephonelists=res.item[0].vehiclephonelists
          if(this.faultmessage==1){
            this.faultlists.push('faultmessage')
          }
          if(this.faultphone==1){
            this.faultlists.push('faultphone')
          }
          if(this.alarmmessage==1){
            this.alarmlists.push('alarmmessage')
          }
          if(this.alarmphone==1){
            this.alarmlists.push('alarmphone')
          }
          if(this.lingeringmessage==1){
            this.lingeringlists.push('lingeringmessage')
          }
          if(this.lingeringphone==1){
            this.lingeringlists.push('lingeringphone')
          }
          if(this.vehiclemessage==1){
            this.vehiclelists.push('vehiclemessage')
          }
          if(this.vehiclephone==1){
            this.vehiclelists.push('vehiclephone')
          }
        }
      })
    },
    setParamsConfig(){

      // let param = {
      //   liftid:this.liftid,
      // }
      // getLiftinfoById(param).then(res=>{
      //   if(res&&res.returncode=='0'){
      //     let param=res.item
      //     modifyLiftinfo(param)
      //   }
      // })
      let params={
        devicenum:this.devicenum,
        faultmessage:this.faultmessage,
        faultphone:this.faultphone,
        faultphonelists:this.faultphonelists.toString(),
        alarmmessage:this.alarmmessage,
        alarmphone:this.alarmphone,
        alarmphonelists:this.alarmphonelists.toString(),
        lingeringmessage:this.lingeringmessage,
        lingeringphone:this.lingeringphone,
        lingerphonelists:this.lingerphonelists.toString(),
        vehiclemessage:this.vehiclemessage,
        vehiclephone:this.vehiclephone,
        vehiclephonelists:this.vehiclephonelists.toString(),
      }
      addEventLinkage(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("参数下发成功")
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
        this.getLiftInfo()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  background-color: white;
  border-radius: 5px;
}
</style>