<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-top: 1.6%;margin-right: 5%;margin-left: 1%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="SelfTestFlag.selfTestFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="状态自检频率" prop="para_self_ins_frequency">
        <a-input v-model="selfTestConfigs.para_self_ins_frequency" placeholder="默认15分钟一次" suffix="分钟/次"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检发送时间段开始时间" prop="para_self_ins_begin">
        <a-input v-model="selfTestConfigs.para_self_ins_begin" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检发送时间段结束时间" prop="para_self_ins_end">
        <a-input v-model="selfTestConfigs.para_self_ins_end" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检CPU使用率阈值" prop="para_self_ins_cpu_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_cpu_threshold" placeholder="默认0.9"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检内存使用率阈值" prop="para_self_ins_rapara_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_ram_threshold" placeholder="默认0.8"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检emmc使用率阈值" prop="para_self_ins_emmc_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_emmc_threshold" placeholder="默认0.8"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检TF卡使用率阈值" prop="para_self_ins_tf_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_tf_threshold" placeholder="默认0.99"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检CPU温度阈值" prop="para_self_ins_cpu_tmp_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_cpu_tmp_threshold" placeholder="默认85"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检GPU温度阈值" prop="para_self_ins_gpu_tmp_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_gpu_tmp_threshold" placeholder="默认85"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检高度差阈值" prop="para_self_ins_height_difference_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_height_difference_threshold" placeholder="默认1.5m" suffix="米"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检安全回路插片-电梯速度阈值" prop="para_self_ins_speed_safty_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_speed_safty_threshold" placeholder="默认1m" suffix="米"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检检修插片-电梯速度阈值" prop="para_self_ins_speed_overhaul_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_speed_overhaul_threshold" placeholder="默认85"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检高度差异常事件后上传状态信息最大数量" prop="para_self_ins_state_upload_max">
        <a-input v-model="selfTestConfigs.para_self_ins_state_upload_max" placeholder="默认50条" suffix="条"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "selfTest",
  props:{
    SelfTestFlag:{
      default: null
    }
  },
  data() {
    return {
      eventModal:false,
      checkedEventKeys:[],
      selfTestConfigs:{
        para_self_ins_frequency:null,
        para_self_ins_begin:null,
        para_self_ins_end:null,
        para_self_ins_cpu_threshold:null,
        para_self_ins_ram_threshold:null,
        para_self_ins_emmc_threshold:null,
        para_self_ins_tf_threshold:null,
        para_self_ins_cpu_tmp_threshold:null,
        para_self_ins_gpu_tmp_threshold:null,
        para_self_ins_height_difference_threshold:null,
        para_self_ins_speed_safty_threshold:null,
        para_self_ins_speed_overhaul_threshold:null,
        para_self_ins_state_upload_max:null,
      },
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      methodList: [],
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '532';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.checkedEventKeys=[];
      this.paramsList = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: "6",
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/selfins',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.selfTestConfigs=res.item[0].params.body
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig(){
      let paramsBody={};
      this.showLoading();
      paramsBody = {
        para_self_ins_frequency:Number(this.selfTestConfigs.para_self_ins_frequency),
        para_self_ins_begin:Number(this.selfTestConfigs.para_self_ins_begin),
        para_self_ins_end:Number(this.selfTestConfigs.para_self_ins_end),
        para_self_ins_cpu_threshold:Number(this.selfTestConfigs.para_self_ins_cpu_threshold),
        para_self_ins_ram_threshold:Number(this.selfTestConfigs.para_self_ins_ram_threshold),
        para_self_ins_emmc_threshold:Number(this.selfTestConfigs.para_self_ins_emmc_threshold),
        para_self_ins_tf_threshold:Number(this.selfTestConfigs.para_self_ins_tf_threshold),
        para_self_ins_cpu_tmp_threshold:Number(this.selfTestConfigs.para_self_ins_cpu_tmp_threshold),
        para_self_ins_gpu_tmp_threshold:Number(this.selfTestConfigs.para_self_ins_gpu_tmp_threshold),
        para_self_ins_height_difference_threshold:Number(this.selfTestConfigs.para_self_ins_height_difference_threshold),
        para_self_ins_speed_safty_threshold:Number(this.selfTestConfigs.para_self_ins_speed_safty_threshold),
        para_self_ins_speed_overhaul_threshold:Number(this.selfTestConfigs.para_self_ins_speed_overhaul_threshold),
        para_self_ins_state_upload_max:Number(this.selfTestConfigs.para_self_ins_state_upload_max)
      }
      this.paramsdata="状态自检频率"+","+this.selfTestConfigs.para_self_ins_frequency+","
          +"自检发送时间段开始时间"+","+this.selfTestConfigs.para_self_ins_begin+","
          +"自检发送时间段结束时间"+","+this.selfTestConfigs.para_self_ins_end+","
          +"自检CPU使用率阈值"+","+this.selfTestConfigs.para_self_ins_cpu_threshold+","
          +"自检内存使用率阈值"+","+this.selfTestConfigs.para_self_ins_ram_threshold+","
          +"自检emmc使用率阈值"+","+this.selfTestConfigs.para_self_ins_emmc_threshold+","
          +"自检TF卡使用率阈值"+","+this.selfTestConfigs.para_self_ins_tf_threshold+","
          +"自检CPU温度阈值"+","+this.selfTestConfigs.para_self_ins_cpu_tmp_threshold+","
          +"自检GPU温度阈值"+","+this.selfTestConfigs.para_self_ins_gpu_tmp_threshold+","
          +"自检高度差阈值"+","+this.selfTestConfigs.para_self_ins_height_difference_threshold+","
          +"自检安全回路插片-电梯速度阈值"+","+this.selfTestConfigs.para_self_ins_speed_safty_threshold+","
          +"自检检修插片-电梯速度阈值"+","+this.selfTestConfigs.para_self_ins_speed_overhaul_threshold+","
          +"自检高度差异常事件后上传状态信息最大数量"+","+this.selfTestConfigs.para_self_ins_state_upload_max+","
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: "6",
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url:'/selfins',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username:this.username,
            paramsobj:this.devicename,
            paramsdate:paramsdate,
            devicenum:this.devicenum,
            devicegroupid:"00",
            orderresult:'成功',
            paramstype:'0',
            parentid:"",
            paramsdata:this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>