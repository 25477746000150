import { render, staticRenderFns } from "./linkage.vue?vue&type=template&id=038b88da&scoped=true&"
import script from "./linkage.vue?vue&type=script&lang=js&"
export * from "./linkage.vue?vue&type=script&lang=js&"
import style0 from "./linkage.vue?vue&type=style&index=0&id=038b88da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038b88da",
  null
  
)

export default component.exports