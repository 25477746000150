<!--参数配置页面-->
<template>
  <page-container title="设备详情" :breadcrumb="breadcrumb">
    <div v-if="!$route.params.devicemoduletype" class="tab-container tab-bottom0">
      <a-tabs v-model="devicemoduletype" size="small">
        <a-tab-pane key="1" tab="基础音视频模块"></a-tab-pane>
        <a-tab-pane key="2" tab="物联网模块"></a-tab-pane>
        <a-tab-pane key="3" tab="视频AI模块"></a-tab-pane>
        <a-tab-pane key="4" tab="通话服务模块"></a-tab-pane>
        <a-tab-pane key="5" tab="语音AI模块"></a-tab-pane>
        <a-tab-pane v-if="paramFlag.selfTestFlag==1" key="6" tab="自检模块"></a-tab-pane>
        <a-tab-pane key="7" tab="联动模块"></a-tab-pane>
      </a-tabs>
      <div style=""></div>
    </div>
    <div class="detail-page">
      <div class="left-tabs">
        <a-menu @click="clickLeftMenu" :selected-keys="selectedKeys" :style="{ borderRight: 0 }">
          <a-menu-item v-for="item in methodList" :key="item.devparamcfgid">{{item.devparamcfgname}}</a-menu-item>
        </a-menu>
      </div>
      <div class="page-content">
        <div class="right-tabs">
          <mqtt v-if="pages=='mqtt'" :MqttFlag="paramFlag" ref="mqtt"></mqtt>
          <sipserver v-if="pages=='sipserver'" ref="sipserver"></sipserver>
          <sipregister v-if="pages=='sipregister'" ref="sipregister"></sipregister>
          <osd v-if="pages=='osd'" ref="osd"></osd>
          <display v-if="pages=='display'" :DisplayFlag="paramFlag" ref="display"></display>
          <videos v-if="pages=='videos'" ref="videos"></videos>
          <network-ntp v-if="pages=='networkNtp'" ref="networkNtp"></network-ntp>
          <video-param v-if="pages=='videoParam'" ref="videoParam" :VideoParamFlag="paramFlag"></video-param>
          <video-params v-if="pages=='videoParams'" ref="videoParams" :VideoParamsFlag="paramFlag"></video-params>
          <deviceinfo v-if="pages=='deviceinfo'" ref="deviceinfo"></deviceinfo>
          <network v-if="pages=='network'" ref="network"></network>
          <iot v-if="pages=='iot'" :IotFlag="paramFlag" ref="iot"></iot>
          <videoAi v-if="pages=='videoAi'" :VideoAiFlag="paramFlag" ref="videoAi"></videoAi>
          <linphone v-if="pages=='linphone'" :LinphoneFlag="paramFlag" ref="linphone"></linphone>
          <selfTest v-if="pages=='selfTest'" :SelfTestFlag="paramFlag" ref="selfTest"></selfTest>
          <linkage v-if="pages=='linkage'" :LinkageFlag="paramFlag" ref="linkage"></linkage>
        </div>
      </div>
    </div>
  </page-container>
</template>
<script>
import { getItemFromArrayByKey, objectToArray, arrayToObject } from 'U'
import { getDeviceparamChildLisByParentId, getDeviceparamValueList, setDeviceparamValueList } from 'A/ai.js'
import mqtt from "V/ai/monitoringEquipment/paramsTreeManager/params/mqtt";
import sipserver from "V/ai/monitoringEquipment/paramsTreeManager/params/sipserver";
import sipregister from "V/ai/monitoringEquipment/paramsTreeManager/params/sipregister";
import osd from "V/ai/monitoringEquipment/paramsTreeManager/params/osd";
import display from "V/ai/monitoringEquipment/paramsTreeManager/params/display";
import iot from "V/ai/monitoringEquipment/paramsTreeManager/params/iot";
import videos from "V/ai/monitoringEquipment/paramsTreeManager/params/videos";
import networkNtp from "V/ai/monitoringEquipment/paramsTreeManager/params/networkNtp";
import deviceinfo from "V/ai/monitoringEquipment/paramsTreeManager/params/deviceinfo";
import videoAi from "V/ai/monitoringEquipment/paramsTreeManager/params/videoAi";
import linphone from "V/ai/monitoringEquipment/paramsTreeManager/params/linphone";
import selfTest from "V/ai/monitoringEquipment/paramsTreeManager/params/selfTest";
import linkage from "V/ai/monitoringEquipment/paramsTreeManager/params/linkage";
import paramsFormlist from './paramsFormlist'
import {getDeviceparamListByCondition} from "A/ai";
import network from "V/ai/monitoringEquipment/paramsTreeManager/params/network";
import videoParam from "V/ai/monitoringEquipment/paramsTreeManager/params/videoParam";
import videoParams from "V/ai/monitoringEquipment/paramsTreeManager/params/videoParams";
export default {
  name: 'paramsConfig',
  components: {
    paramsFormlist,
    mqtt,
    sipserver,
    sipregister,
    iot,
    videoAi,
    linphone,
    osd,
    display,
    videos,
    deviceinfo,
    network,
    selfTest,
    linkage,
    videoParam,
    videoParams,
    networkNtp,
  },
  data() {
    return {
      breadcrumb: [
        ...(this.$route.params.prePages||[]),
        {
          name: '参数配置',
          path: ''
        },
      ],
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      liftid:'',
      methodList: [],
      tabKey: 404,
      paramsList: [],
      pages:'mqtt',
      paramList:[],
      parentid:'',
      devparammodel:'',
      iotFlag:1,
      paramFlag:{},
    }
  },
  computed: {
    selectedKeys() {
      return [this.tabKey]
    }
  },
  watch: {
    devicemoduletype(val) {
      if(val) {
        this.getMethods();
        // this.getFirstParam(val);
      }
    },
    tabKey(val) {
      if(val) {
        // this.getParamsConfig();
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      this.liftid = this.$route.params.liftid||'';
      this.paramFlag = this.$route.params.paramFlag||'';
    },
    getFirstParam(val){
      this.methodList = [];
      this.tabKey = '';
      if(val==1){
        this.parentid=1
        this.devparammodel=2
      }else if(val==2){
        this.parentid=2
        this.devparammodel=3
      }else if(val==3){
        this.parentid=3
        this.devparammodel=3
      }else if(val==4){
        this.parentid=4
        this.devparammodel=3
      }else if(val==5){
        this.parentid=5
        this.devparammodel=3
      }else if(val==6){
        this.parentid=6
        this.devparammodel=3
      } else if(val==7){
        this.parentid=7
        this.devparammodel=3
      }
      let params = {
        parentid: this.parentid,
        devparammodel:this.devparammodel
      };
      getDeviceparamListByCondition(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
          this.methodList=this.methodList.item
          this.tabKey = this.methodList.item[0].devparamcfgid;
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
          this.tabKey = this.methodList[0].devparamcfgid;
        }
      }).catch(err => {
        this.hideLoading();
      })
      if(this.devicemoduletype==1){this.pages='mqtt'};
      if(this.devicemoduletype==2){this.pages='iot'};
      if(this.devicemoduletype==3){this.pages='videoAi'};
      if(this.devicemoduletype==4){this.pages='linphone'};
      if(this.devicemoduletype==5){this.pages=''};
      if(this.devicemoduletype==6){this.pages='selfTest'};
      if(this.devicemoduletype==7){this.pages='linkage'};
    },
    clickLeftMenu(event) {
      this.tabKey = event.key;
      if(this.tabKey==404){this.pages='mqtt'};
      if(this.tabKey==405){this.pages='sipserver'};
      if(this.tabKey==406){this.pages='sipregister'};
      if(this.tabKey==526){this.pages='osd'};
      if(this.tabKey==528){this.pages='display'};
      if(this.tabKey==529){this.pages='videos'};
      if(this.tabKey==535){this.pages='networkNtp'};
      if(this.tabKey==530){this.pages='deviceinfo'};
      if(this.tabKey==531){this.pages='network'};
      if(this.tabKey==534){this.pages='videoParam'};
      if(this.tabKey==536){this.pages='videoParams'};
      if(this.tabKey==20){this.pages='iot'};
      if(this.tabKey==44){this.pages='videoAi'};
      if(this.tabKey==88){this.pages='linphone'};
      if(this.tabKey==532){this.pages='selfTest'};
      if(this.tabKey==533){this.pages='linkage'};
    },


    getParamsConfig() {
      this.paramsList = [];
      let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      if(selectedMethod) {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'GET',
                url: selectedMethod.url+'&action=getconfig',
              }
            }
          ]
        };
        this.showLoading();
        getDeviceparamValueList(params).then(res => {
          this.hideLoading();
          let paramsList = [];
          if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
            paramsList = objectToArray(res.item[0].params.body, res.schema);
          }
          this.paramsList = paramsList;
        }).catch(err => {
          this.hideLoading();
        })
      }
    },
    setParamsConfig() {
      console.log(this.paramsList)
      let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      if(selectedMethod) {
        let paramsBody = arrayToObject(this.paramsList);
        if("transMode"in paramsBody) {
          paramsBody.port = Number(paramsBody.port);
          paramsBody.transMode = Number(paramsBody.transMode);
          paramsBody.timeout = Number(paramsBody.timeout);
          paramsBody.regInterval = Number(paramsBody.regInterval);
          paramsBody.regExpires = Number(paramsBody.regExpires);
          paramsBody.heartInterval = Number(paramsBody.heartInterval);
          paramsBody.heartTimes = Number(paramsBody.heartTimes);
          paramsBody.expires = Number(paramsBody.expires);
        };
        if("host" in paramsBody){
          paramsBody["host"].port=Number(paramsBody["host"].port);
          // paramsBody["host"].tls=Number(paramsBody["host"].tls);
        };
        if("PRESSURE_PRECISION"in paramsBody){
          paramsBody.heartbeat_cycle = Number(paramsBody.heartbeat_cycle);
          paramsBody.version_number = Number(paramsBody.version_number);
          paramsBody.PRESSURE_PRECISION =  Number(paramsBody.PRESSURE_PRECISION);
          paramsBody.BASE_PTH = Number(paramsBody.BASE_PTH);
          paramsBody.open_area_pressure_threshold = Number(paramsBody.open_area_pressure_threshold);
          paramsBody.T  = Number(paramsBody.T);
          // paramsBody.T_center  = Number(paramsBody.T_center)
          paramsBody.PRESSUER_LIST_MAX  = Number(paramsBody.PRESSUER_LIST_MAX)
          paramsBody.LISTEN_PORT  = Number(paramsBody.LISTEN_PORT)
          paramsBody.SEND_to_mqtt_PORT  = Number(paramsBody.SEND_to_mqtt_PORT)
          paramsBody.SEND_to_linphone_PORT = Number(paramsBody.SEND_to_linphone_PORT)
          paramsBody.FAULE_CLOSE_DOOR_TIME = Number(paramsBody.FAULE_CLOSE_DOOR_TIME)
          paramsBody.FAULE_OPEN_DOOR_TIME  = Number(paramsBody.FAULE_OPEN_DOOR_TIME)
          paramsBody.TRAPPED_PEOPLE_TIME = Number(paramsBody.TRAPPED_PEOPLE_TIME)
          paramsBody.TRAPPED_PEOPLE_OUT_AREA_TIME  = Number(paramsBody.TRAPPED_PEOPLE_OUT_AREA_TIME)
          paramsBody.AI_REALSTATE_RECEIVE_TIME = Number(paramsBody.AI_REALSTATE_RECEIVE_TIME)
          paramsBody.FAULE_ALARM_CODE  = Number(paramsBody.FAULE_ALARM_CODE)
          paramsBody.FAULE_ALARM_CANCEL_CODE  = Number(paramsBody.FAULE_ALARM_CANCEL_CODE)
          paramsBody.FAULE_SAFETY_LOOP_CODE  = Number(paramsBody.FAULE_SAFETY_LOOP_CODE)
          paramsBody.FAULE_SAFETY_LOOP_CANCEL_CODE = Number(paramsBody.FAULE_SAFETY_LOOP_CANCEL_CODE)
          paramsBody.FAULE_CLOSE_CODE  = Number(paramsBody.FAULE_CLOSE_CODE)
          paramsBody.FAULE_CLOSE_CANCEL_CODE = Number(paramsBody.FAULE_CLOSE_CANCEL_CODE)
          paramsBody.FAULE_OPEN_CODE = Number(paramsBody.FAULE_OPEN_CODE)
          paramsBody.FAULE_OPEN_CANCEL_CODE  = Number(paramsBody.FAULE_OPEN_CANCEL_CODE)
          paramsBody.TRAPPED_PEOPLE_CODE  = Number(paramsBody.TRAPPED_PEOPLE_CODE)
          paramsBody.TRAPPED_PEOPLE_CANCEL_CODE  = Number(paramsBody.TRAPPED_PEOPLE_CANCEL_CODE)
          paramsBody.STOP_OUT_OPEN_AREA_CODE  = Number(paramsBody.STOP_OUT_OPEN_AREA_CODE)
          paramsBody.STOP_OUT_OPEN_AREA_CANCEL_CODE = Number(paramsBody.STOP_OUT_OPEN_AREA_CANCEL_CODE)
          paramsBody.OTHER_FAULT_CODE = Number(paramsBody.OTHER_FAULT_CODE)
          paramsBody.OTHER_FAULT_CANCEL_CODE  = Number(paramsBody.OTHER_FAULT_CANCEL_CODE)
          paramsBody.accidental_movement_code = Number(paramsBody.accidental_movement_code)
          paramsBody.accidental_movement_cancel_code  = Number(paramsBody.accidental_movement_cancel_code)
          paramsBody.cumulative_run_nums = Number(paramsBody.cumulative_run_nums)
          paramsBody.cumulative_run_time  = Number(paramsBody.cumulative_run_time)
          paramsBody.cumulative_door_status  = Number(paramsBody.cumulative_door_status)
          // paramsBody.update_pressure_threshold = Number(paramsBody.update_pressure_threshold)
          // paramsBody.T_for_other_fault  = Number(paramsBody.T_for_other_fault)
          // paramsBody.detect_other_fault_continuation_times = Number(paramsBody.detect_other_fault_continuation_times)
          // paramsBody.update_pressure_continuation_times = Number(paramsBody.update_pressure_continuation_times)
          // paramsBody.T_for_update_pressure_after_out_open_area  = Number(paramsBody.T_for_update_pressure_after_out_open_area)
          paramsBody.T_for_stop_out_open_area  = Number(paramsBody.T_for_stop_out_open_area)
          paramsBody.detect_fault_out_open_area_continuation_times = Number(paramsBody.detect_fault_out_open_area_continuation_times)
          paramsBody.detect_out_open_area_continuation_times  = Number(paramsBody.detect_out_open_area_continuation_times)
          // paramsBody.start_stabilize  = Number(paramsBody.start_stabilize)
          paramsBody.cumulative_person_nums  = Number(paramsBody.cumulative_person_nums)
          paramsBody.cumulative_person_nums_daily  = Number(paramsBody.cumulative_person_nums_daily)
          paramsBody.cumulative_person_const = Number(paramsBody.cumulative_person_const)
          paramsBody.trapped_person_appear_probability  = Number(paramsBody.trapped_person_appear_probability)
          paramsBody.cumulative_door_status_daily = Number(paramsBody.cumulative_door_status_daily)
          paramsBody.out_cancel_car_move_continuation_times = Number(paramsBody.out_cancel_car_move_continuation_times)
          paramsBody.car_state_continuation_times  = Number(paramsBody.car_state_continuation_times)
          paramsBody.out_car_stop_state_continuation_times  = Number(paramsBody.out_car_stop_state_continuation_times)
          paramsBody.floor_segmentation_height_list_len = Number(paramsBody.floor_segmentation_height_list_len)
          paramsBody.all_fault_enable = Number(paramsBody.all_fault_enable);
          paramsBody.out_detect_enable_flag = Number(paramsBody.out_detect_enable_flag)
          paramsBody.temp_deviation  = Number(paramsBody.temp_deviation)
          paramsBody.out_threshold_coef = Number(paramsBody.out_threshold_coef)
          paramsBody.out_threshold_coef_dyn = Number(paramsBody.out_threshold_coef_dyn)
          paramsBody.after_up_no_out_fault_time = Number(paramsBody.after_up_no_out_fault_time)
          paramsBody.alarm_enable = Number(paramsBody.alarm_enable)
          paramsBody.welcome_audio_enable = Number(paramsBody.welcome_audio_enable)
          paramsBody.other_fault_coef = Number(paramsBody.other_fault_coef)
          paramsBody.speed_max = Number(paramsBody.speed_max)
          paramsBody.motor_threshold = Number(paramsBody.motor_threshold)
          paramsBody.motor_time_threshold = Number(paramsBody.motor_time_threshold)
          paramsBody.loss_floor_pressure_lowest_threshold = Number(paramsBody.loss_floor_pressure_lowest_threshold)
          paramsBody.loss_floor_pressure_highest_threshold = Number(paramsBody.loss_floor_pressure_highest_threshold)
          paramsBody.loss_floor_continuation_times = Number(paramsBody.loss_floor_continuation_times)
          paramsBody.fault_motor_code = Number(paramsBody.fault_motor_code)
          paramsBody.fault_motor_cancel_code = Number(paramsBody.fault_motor_cancel_code)
          paramsBody.fault_floor_loss_code = Number(paramsBody.fault_floor_loss_code)
          paramsBody.fault_floor_loss_cancel_code = Number(paramsBody.fault_floor_loss_cancel_code)
          paramsBody.alarm_active_enable = Number(paramsBody.alarm_active_enable)
        };

        if("INIT_WITH_VEHICLE_THRESHOLD"in paramsBody){
          paramsBody.INIT_WITH_VEHICLE_THRESHOLD = Number(paramsBody.INIT_WITH_VEHICLE_THRESHOLD)
          paramsBody.INIT_WITH_VEHICLE_HEAD_THRESHOLD = Number(paramsBody.INIT_WITH_VEHICLE_HEAD_THRESHOLD)
          paramsBody.INIT_WITH_VEHICLE_CHAIR_THRESHOLD = Number(paramsBody.INIT_WITH_VEHICLE_CHAIR_THRESHOLD)
          paramsBody.INIT_WITH_FIGHT_THRESHOLD  = Number(paramsBody.INIT_WITH_FIGHT_THRESHOLD)
          paramsBody.INIT_WITH_FISTHEAD_THRESHOLD = Number(paramsBody.INIT_WITH_FISTHEAD_THRESHOLD)
          paramsBody.VEHICLE_QUEUE_SIZE = Number(paramsBody.VEHICLE_QUEUE_SIZE)
          paramsBody.VEHICLE_QUEUE_NUM = Number(paramsBody.VEHICLE_QUEUE_NUM)
          paramsBody.VEHICLE_CHAIR_HEAD_SIZE  = Number(paramsBody.VEHICLE_CHAIR_HEAD_SIZE)
          paramsBody.VEHICLE_CHAIR_HEAD_NUM  = Number(paramsBody.VEHICLE_CHAIR_HEAD_NUM)
          paramsBody.FIGHT_QUEUE_SIZE = Number(paramsBody.FIGHT_QUEUE_SIZE)
          paramsBody.FIGHT_QUEUE_NUM  = Number(paramsBody.FIGHT_QUEUE_NUM)
          paramsBody.FISTHEAD_QUEUE_SIZE = Number(paramsBody.FISTHEAD_QUEUE_SIZE)
          paramsBody.FISTHEAD_QUEUE_NUM = Number(paramsBody.FISTHEAD_QUEUE_NUM)
        };

        if("listen_port"in paramsBody){
          paramsBody.listen_port = Number(paramsBody.listen_port)
          paramsBody.send_port  = Number(paramsBody.send_port)
          paramsBody.check_register_rand_time_max  = Number(paramsBody.check_register_rand_time_max)
          paramsBody.check_register_rand_time_min  = Number(paramsBody.check_register_rand_time_min)
          paramsBody.after_init_wait_time  = Number(paramsBody.after_init_wait_time)
          paramsBody.after_register_wait_time = Number(paramsBody.after_register_wait_time)
          paramsBody.repeat_register_everytime_wait_time = Number(paramsBody.repeat_register_everytime_wait_time)
        };

        // console.log("host"in paramsBody)
        console.log(paramsBody);
        let params = {
          devicenum: this.devicenum,
            data: [
              {
                modeltype: this.devicemoduletype,
                modelnum: this.modelnum,
                params: {
                  methord: 'PUT',
                  url:selectedMethod.url+'&action=setconfig',
                  // url: selectedMethod.url,
                  body: paramsBody,
                }
              }
            ]
        };
        this.showLoading();
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tab-container {
  padding: 0 24px;
  background-color: white;
}
.detail-page {
  display: flex;
  padding: 24px;
  .left-tabs {
    flex-shrink: 0;
    width: 180px;
    min-height: calc(100vh - 200px);
    padding-top: 20px;
    background-color: white;
    border-radius: 2px;
  }
  .page-content {
    flex-grow: 1;
    margin-left: 24px;
  }
  .config-btn {
    padding-right: 4.1%;
    padding-bottom: 10px;
    overflow: hidden;
  }
}
</style>