<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-top: 1.6%;margin-right: 6.1%;margin-left: 1%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="LinphoneFlag.linphoneFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="通话服务软终端是否开启" prop="linphone_enable">
        <a-select v-model="linphoneConfigs.linphone_enable" placeholder="1开启，0关闭，默认开启">
          <a-select-option :value="1">开启</a-select-option>
          <a-select-option :value="0">关闭</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分机号" prop="username">
        <a-input v-model="linphoneConfigs.username" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="救援人员姓名" prop="rescuepreson">
        <a-input v-model="linphoneConfigs.rescuepreson" placeholder="多个人员请使用英文逗号分隔"></a-input>
      </a-form-model-item>
      <a-form-model-item label="救援人员电话" prop="numbers">
        <a-input v-model="linphoneConfigs.numbers" placeholder="多个电话请使用英文逗号分隔"></a-input>
      </a-form-model-item>
      <a-form-model-item label="喇叭音量" prop="master">
        <a-input v-model="linphoneConfigs.master" placeholder="默认80%" suffix="%"></a-input>
      </a-form-model-item>
      <a-form-model-item label="话筒输入量" prop="digital">
        <a-input v-model="linphoneConfigs.digital" placeholder="默认80%" suffix="%"></a-input>
      </a-form-model-item>
<!--      <a-form-model-item label="故障联动终端通话使能" prop="eventcode_fault">-->
<!--        <a-input placeholder="请选择" @click="roleModal=true"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="电梯事件联动终端通话使能" prop="eventcode_liftevent">-->
<!--        <a-input placeholder="请选择" @click="roleModal1=true"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="报警联动终端通话使能" prop="digital">-->
<!--        <a-input v-model="linphoneConfigs.digital" placeholder="0：禁用,1：使能,默认为0"></a-input>-->
<!--      </a-form-model-item>-->

    </a-form-model>
    <a-modal v-model="roleModal" title="故障联动通话使能分配" :bodyStyle="{minHeight: '200px', maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button @click="roleModal=false">取消</a-button>
        <a-button type="primary" @click="setAble">确认</a-button>
      </template>
      <div class="role-modal-container">
        <a-transfer :titles="['禁用', '使能']" :render="item => item.title" :target-keys="checkedKeys" :data-source="faultData" @change="ableCheckedChange" />
      </div>
    </a-modal>
    <a-modal v-model="roleModal1" title="电梯事件联动通话使能分配" :bodyStyle="{minHeight: '200px', maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button @click="roleModal1=false">取消</a-button>
        <a-button type="primary" @click="setAble">确认</a-button>
      </template>
      <div class="role-modal-container">
        <a-transfer :titles="['禁用', '使能']" :render="item => item.title" :target-keys="checkedKeys1" :data-source="lifteventData" @change="ableCheckedChange1" />
      </div>
    </a-modal>
    <a-form-model v-if="LinphoneFlag.linphoneFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="sip服务器地址" prop="host">
        <a-input v-model="linphoneConfigs.host" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="注册密码" prop="password">
        <a-input type="password" v-model="linphoneConfigs.password" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="监听端口" prop="listen_port">
        <a-input v-model="linphoneConfigs.listen_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="发送端口" prop="send_port">
        <a-input v-model="linphoneConfigs.send_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="通话服务模块心跳周期" prop="heartbeat_cycle">
        <a-input v-model="linphoneConfigs.heartbeat_cycle" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="循环注册时间最大值" prop="check_register_rand_time_max">
        <a-input v-model="linphoneConfigs.check_register_rand_time_max" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="循环注册时间最小值" prop="check_register_rand_time_min">
        <a-input v-model="linphoneConfigs.check_register_rand_time_min" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="每次通话服务模块初始化后需要等待时间" prop="after_init_wait_time">
        <a-input v-model="linphoneConfigs.after_init_wait_time" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="每次重新注册后需要等待时间" prop="after_register_wait_time">
        <a-input v-model="linphoneConfigs.after_register_wait_time" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="注册失败时循环尝试注册时间间隔" prop="repeat_register_everytime_wait_time">
        <a-input v-model="linphoneConfigs.repeat_register_everytime_wait_time" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="拨打电话期间循环检测电话是否结束的时间" prop="check_call_done_time">
        <a-input v-model="linphoneConfigs.check_call_done_time" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList,
  modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import {
  getLiftinfoById,
  modifyLiftinfo,
} from "A/jcgn";
import {getEventListByCondition} from "A/wlw";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "linphone",
  props:{
    LinphoneFlag:{
      default: null
    }
  },
  data() {
    return {
      roleModal:false,
      roleModal1:false,
      linphoneConfigs:{
        heartbeat_cycle:'',
        linphone_enable:'',
        username:'',
        host:'',
        password:'',
        check_register_rand_time_max:'',
        check_register_rand_time_min:'',
        after_init_wait_time:'',
        after_register_wait_time:'',
        repeat_register_everytime_wait_time:'',
        listen_port:'',
        send_port:'',
        rescuepreson:'',
        numbers:'',
        check_call_done_time:'',
        gpio_enable:'',
        gpio_active_enable:'',
        master:'',
        digital:'',
      },
      eventcode:'',
      eventcode_fault:'',
      eventcode_liftevent:'',
      checkedKeys:[],
      faultData:[],
      faultOptions:[],
      checkedKeys1:[],
      lifteventData:[],
      lifteventOptions:[],
      devicemoduletype: '',
      devicenum: '',
      devicename:'',
      modelnum: '',
      liftid:'',
      methodList: [],
      tabKey: '88',
      status:false,
      userdepid:'',
      userdepname:'',
      levelid:'',
      deptype:'',
      username:'',
      paramsdata:'',
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      this.deviceinfoid=this.$route.params.deviceinfoid||'';
      this.liftid = this.$route.params.liftid||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
      this.initLiftEventOptions();
      this.initFaultOptions();
    },
    initFaultOptions(){
      let params={
        eventtype:'1'
      }
      getEventListByCondition(params).then(res=>{
        if (res && res.returncode == '0') {
          this.faultOptions = res.item;
          for(let i=0;i<this.faultOptions.length;i++){
            this.faultData.push({
              key: this.faultOptions[i].eventcode.toString(),
              title: this.faultOptions[i].eventname,
            });
          }
          this.faultOptions.unshift({eventcode:"",eventname:"请选择"})

        }
      })
    },
    initLiftEventOptions(){
      let params={
        eventtype:'4'
      }
      getEventListByCondition(params).then(res=>{
        if (res && res.returncode == '0') {
          this.lifteventOptions = res.item;
          for(let i=0;i<this.lifteventOptions.length;i++){
            this.lifteventData.push({
              key: this.lifteventOptions[i].eventcode.toString(),
              title: this.lifteventOptions[i].eventname,
            });
          }
          this.lifteventOptions.unshift({eventcode:"",eventname:"请选择"})
        }
      })
    },
    setAble(){
      console.log(this.checkedKeys)
      console.log(this.checkedKeys1)
    },
    ableCheckedChange(targetKeys){
      this.checkedKeys = targetKeys;
    },
    ableCheckedChange1(targetKeys){
      this.checkedKeys1 = targetKeys;
    },
    getLiftInfo(){
      let params = {
        liftid:this.liftid
      }
      getLiftinfoById(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.userdepid=res.item.maintenancedepLists.userdepid,
          this.userdepname=res.item.maintenancedepLists.userdepname,
          this.levelid=res.item.maintenancedepLists.levelid,
          this.deptype=res.item.maintenancedepLists.deptype
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '88';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getParamsConfig() {
      this.paramsList = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/linphone',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.linphoneConfigs.heartbeat_cycle=res.item[0].params.body.heartbeat_cycle
          this.linphoneConfigs.linphone_enable=res.item[0].params.body.linphone_enable
          this.linphoneConfigs.username=res.item[0].params.body.username
          this.linphoneConfigs.host=res.item[0].params.body.host
          this.linphoneConfigs.password=res.item[0].params.body.password
          this.linphoneConfigs.check_register_rand_time_max=res.item[0].params.body.check_register_rand_time_max
          this.linphoneConfigs.check_register_rand_time_min=res.item[0].params.body.check_register_rand_time_min
          this.linphoneConfigs.after_init_wait_time=res.item[0].params.body.after_init_wait_time
          this.linphoneConfigs.after_register_wait_time=res.item[0].params.body.after_register_wait_time
          this.linphoneConfigs.repeat_register_everytime_wait_time=res.item[0].params.body.repeat_register_everytime_wait_time
          this.linphoneConfigs.listen_port=res.item[0].params.body.listen_port
          this.linphoneConfigs.send_port=res.item[0].params.body.send_port
          this.linphoneConfigs.rescuepreson=res.item[0].params.body.rescuer_name
          this.linphoneConfigs.numbers=res.item[0].params.body.numbers
          this.linphoneConfigs.check_call_done_time=res.item[0].params.body.check_call_done_time
          this.linphoneConfigs.gpio_enable=res.item[0].params.body.gpio_enable
          this.linphoneConfigs.gpio_active_enable=res.item[0].params.body.gpio_active_enable
          this.linphoneConfigs.master=res.item[0].params.body.master
          this.linphoneConfigs.digital=res.item[0].params.body.digital
        }
      }).catch(err => {
        this.hideLoading();
      })
      // }
    },
    setParamsConfig(){
      this.paramsdata='';
      let param = {
        liftid:this.liftid,
      }
      getLiftinfoById(param).then(res=>{
        if(res&&res.returncode=='0'){
          let param=res.item
          param.ipnetnum=this.linphoneConfigs.username,
          param.rescueperson=this.linphoneConfigs.rescuepreson,
          param.rescuephone=this.linphoneConfigs.numbers,
          modifyLiftinfo(param)
        }
      })
      let paramsBody={}
      if(this.LinphoneFlag.linphoneFlag==0){
        paramsBody = {
          linphone_enable: this.linphoneConfigs.linphone_enable,
          username: this.linphoneConfigs.username,
          numbers: this.linphoneConfigs.numbers,
          rescuer_name:this.linphoneConfigs.rescuepreson,
          master: this.linphoneConfigs.master,
          digital: this.linphoneConfigs.digital,
        }
        this.paramsdata="通话服务软终端是否开启"+","+this.linphoneConfigs.linphone_enable+","
            +"分机号"+","+this.linphoneConfigs.username+","
            +"救援人员电话"+","+this.linphoneConfigs.numbers+","
            +"救援人员姓名"+","+this.linphoneConfigs.rescuepreson+","
            +"喇叭音量"+","+this.linphoneConfigs.master+","
            +"话筒输入量"+","+this.linphoneConfigs.digital+","
      }else{
          paramsBody = {
          heartbeat_cycle : this.linphoneConfigs.heartbeat_cycle,
          host : this.linphoneConfigs.host,
          password : this.linphoneConfigs.password,
          check_register_rand_time_max : this.linphoneConfigs.check_register_rand_time_max,
          check_register_rand_time_min : this.linphoneConfigs.check_register_rand_time_min,
          after_init_wait_time : this.linphoneConfigs.after_init_wait_time,
          after_register_wait_time : this.linphoneConfigs.after_register_wait_time,
          repeat_register_everytime_wait_time : this.linphoneConfigs.repeat_register_everytime_wait_time,
          listen_port : this.linphoneConfigs.listen_port,
          send_port : this.linphoneConfigs.send_port,
          check_call_done_time : this.linphoneConfigs.check_call_done_time,
          gpio_enable : this.linphoneConfigs.gpio_enable,
          gpio_active_enable : this.linphoneConfigs.gpio_active_enable,
        }
        this.paramsdata="通话服务模块心跳周期"+","+this.linphoneConfigs.heartbeat_cycle+","
            +"sip服务器地址"+","+this.linphoneConfigs.host+","
            +"注册密码"+","+this.linphoneConfigs.password+","
            +"循环注册时间最大值"+","+this.linphoneConfigs.check_register_rand_time_max+","
            +"循环注册时间最小值"+","+this.linphoneConfigs.check_register_rand_time_min+","
            +"每次通话服务模块初始化后需要等待时间"+","+this.linphoneConfigs.after_init_wait_time+","
            +"每次重新注册后需要等待时间"+","+this.linphoneConfigs.after_register_wait_time+","
            +"注册失败时循环尝试注册时间间隔"+","+this.linphoneConfigs.repeat_register_everytime_wait_time+","
            +"监听端口"+","+this.linphoneConfigs.listen_port+","
            +"发送端口"+","+this.linphoneConfigs.send_port+","
            +"拨打电话期间循环检测电话是否结束的时间"+","+this.linphoneConfigs.check_call_done_time+","
      }
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param1 = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param1)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url:'/linphone',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username:this.username,
            paramsobj:this.devicename,
            paramsdate:paramsdate,
            devicenum:this.devicenum,
            devicegroupid:"00",
            orderresult:'成功',
            paramstype:'0',
            parentid:"",
            paramsdata:this.paramsdata,
          }
          modifyParamsBatchLog(param)
          if(this.LinphoneFlag.linphoneFlag==0){
            let params = {
              liftid:this.liftid,
            }
            getLiftinfoById(params).then(res=>{
              if(res&&res.returncode=='0'){
                let param=res.item
                param.ipnetnum=this.linphoneConfigs.username,
                param.rescueperson=this.linphoneConfigs.rescuepreson,
                param.rescuephone=this.linphoneConfigs.numbers,
                modifyLiftinfo(param)
              }
            })
          }
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
        this.getLiftInfo()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>